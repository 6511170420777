<template>
    <div class="form-group">
      <b-form-group :label="label" :label-for="id" class="form-col" :style="formGroupStyle">
        <slot name="input" :id="id" :input_name="name" :value="value" :handleInput="handleInput" :error="error" :placeholder="placeholder"></slot>
        <b-form-invalid-feedback v-if="error">{{ error }}</b-form-invalid-feedback>
      </b-form-group>
    </div>
  </template>
  
  <script>
  export default {
    name: "FormInput",
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        required: true,
      },
      error: {
        type: String,
        default: null,
      },
      id: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: false,
      },
      formGroupStyle: {
        type: String,
        required: false,
      },
    },
    methods: {
      handleInput(value) {
        this.$emit('input', value);
      },
    },
  };
  </script>